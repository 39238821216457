function LoadingIndicator() {
  return (
    <div className="absolute inset-0 flex items-center justify-center">
      <span
        className={`animate-ping inline-flex h-20 w-20 rounded-full  bg-[#FF9000] opacity-75`}
      />
    </div>
  );
}

export default LoadingIndicator;
