import { memo, useEffect, useRef, useState } from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import './styles.css';
import { VideoJSProps } from '@/types';
import { FiVolume2, FiVolumeX } from 'react-icons/fi';

function VideoJS({ options, onReady, playerRef }: VideoJSProps): JSX.Element {
  const videoRef = useRef<HTMLDivElement>(null);
  const [muted, setMuted] = useState(true);
  const [showIcons, setShowIcons] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setShowIcons(false);
    }, 2000);
  }, []);
  useEffect(() => {
    if (!playerRef.current) {
      const videoElement = document.createElement('video-js');
      videoElement.classList.add('vjs-big-play-centered');
      videoRef.current?.appendChild(videoElement);

      const player = (playerRef.current = videojs(videoElement, options, () => {
        onReady && onReady(player);
      }));
    } else {
      const player = playerRef.current;
      player.autoplay(options.autoplay);
      player.src(options.sources);
    }
    playerRef.current.on('volumechange', () => {
      setShowIcons(true);
      setMuted(Boolean(playerRef.current?.muted()));
      setTimeout(() => {
        setShowIcons(false);
      }, 2000);
    });
  }, [options, videoRef, playerRef, onReady]);

  return (
    <div className="h-full w-full object-cover" data-vjs-player>
      <div ref={videoRef} />
      <div
        className={`absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex justify-center items-center h-[60px] rounded-full w-[60px] bg-black opacity-50 ${showIcons ? 'visible' : 'invisible'}`}
      >
        {muted ? (
          <FiVolumeX size={22} color="white" />
        ) : (
          <FiVolume2 size={22} color="white" />
        )}
      </div>
    </div>
  );
}
export default memo(VideoJS);
