import { memo, useEffect, useRef } from 'react';
import VideoJS from './VideoJS';
import Player from 'video.js/dist/types/player';
import { VideoReelProps } from '@/types';

function VideoReel({ inView, index, src }: VideoReelProps): JSX.Element {
  const playerRef = useRef<Player | null>(null);
  useEffect(() => {
    const handleInteraction = (event: Event) => {
      if (!event.target || !(event.target instanceof HTMLElement)) {
        return;
      }
      const isIconClicked = event.target.classList.contains('icon-class');
      const isModalOpened = event.target.classList.contains(
        'open-get-koinz-app-modal'
      );
      const isHeaderClicked = event.target.classList.contains('custom-header');
      const isModalToggled = event.target.classList.contains('modal-toggle');
      const isModalBackdrop = event.target.classList.contains('modal-backdrop');
      if (playerRef.current) {
        if (isModalToggled || isModalOpened) {
          playerRef.current?.pause();
        } else if (inView && playerRef.current?.id() === index) {
          playerRef.current?.play();
        }
        if (
          !isIconClicked &&
          !isHeaderClicked &&
          !(isModalToggled || isModalOpened || isModalBackdrop)
        ) {
          const newMutedState = !playerRef.current.muted();
          playerRef.current.muted(newMutedState);
        }
      }
    };
    window?.addEventListener('click', handleInteraction);
    return () => {
      window?.removeEventListener('click', handleInteraction);
    };
  }, [inView, index]);

  useEffect(() => {
    if (playerRef.current) {
      const play = () => playerRef.current?.play();
      if (inView && playerRef.current?.id() === index) {
        play();
        playerRef.current.on('end', play);
        playerRef.current.on('play', play);
      } else {
        playerRef.current.pause();
      }
    }
  }, [inView, index]);
  return (
    <VideoJS
      playerRef={playerRef}
      options={{
        id: index.toString(),
        playsinline: true,
        autoplay: false,
        muted: true,
        responsive: true,
        fluid: true,
        fill: true,
        loop: true,
        sources: [
          {
            src,
          },
        ],
      }}
    />
  );
}

export default memo(VideoReel);
