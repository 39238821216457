import './App.css';
import { router } from '@/routes';
import { Suspense, useEffect } from 'react';
import { LoadingIndicator } from './components';
import { RouterProvider } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import i18n from './localization/i18n';
import { isRTL } from './helpers';

function App() {
  useEffect(() => {
    i18n.changeLanguage(isRTL ? 'ar' : 'en');
  }, []);

  return (
    <I18nextProvider i18n={i18n}>
      <Suspense fallback={<LoadingIndicator />}>
        <RouterProvider router={router} />{' '}
      </Suspense>
    </I18nextProvider>
  );
}

export default App;
