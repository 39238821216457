import { lazy } from 'react';
import { createBrowserRouter } from 'react-router-dom';
const NotFound = lazy(() => import('./NotFound'));
const Reels = lazy(() => import('./Reels'));
const InfluencerProfile = lazy(() => import('./InfluencerProfile'));

export const router = createBrowserRouter([
  {
    path: '/reels/:reelId/*',
    element: <Reels />,
  },
  {
    path: '/influencer/:influencerId/*',
    element: <InfluencerProfile />,
  },
  {
    path: '/influencer/:influencerId/reels/:reelId/*',
    element: <Reels />,
  },
  {
    path: '*',
    element: <NotFound />,
  },
]);
