import memoize from 'lodash/memoize';

export const numberFormatter = memoize((num: number, digits: number) => {
  if (num === 0) {
    return 0;
  }
  const numberFormats = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'B' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  let i;
  for (i = numberFormats.length - 1; i > 0; i--) {
    if (num >= numberFormats[i].value) {
      break;
    }
  }
  return (
    (num / numberFormats[i].value).toFixed(digits).replace(rx, '$1') +
    numberFormats[i].symbol
  );
});
