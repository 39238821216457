import { initializeApp } from 'firebase/app';
import {
  logEvent,
  getAnalytics,
  CustomEventName,
  setAnalyticsCollectionEnabled,
} from 'firebase/analytics';
export const initFirebaseApp = () => {
  const firebaseConfig = {
    apiKey: import.meta.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: import.meta.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: import.meta.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: import.meta.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: import.meta.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: import.meta.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: import.meta.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: import.meta.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  };
  initializeApp(firebaseConfig);
  const analytics = getAnalytics();
  setAnalyticsCollectionEnabled(analytics, true);
};

export type EventPayload = {
  ip_address?: string;
  timestamp: number;
  platform: "Android" | "iOS";
  download_source: "Open App Button" | "Get Koinz App";
  reel_id?: string;
  brand_id?: string;
  influencer_id?: string;
};

export type EventParams = {
  app_download_event: EventPayload;
  view_event: Omit<EventPayload, "download_source"> & {
    device_type: string;
    duration_viewed?: string;
    user_type?: string;
  };
};

export const firebaseLogEvent = <T extends keyof EventParams>(
  event_name: T,
  params: EventParams[T]
) => {
  logEvent(getAnalytics(), event_name as CustomEventName<T>, params);
};
